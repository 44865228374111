import React, { useEffect, useState } from 'react'

import Header from '../components/header'
import SEO from '../components/seo'

import Prof from '../svg/prof_visio.svg'
import { colors } from '../utils'
import Footer from '../components/footer'
import Background from '../svg/header.svg'
import { Link } from 'gatsby'
import Logo from '../svg/logo.svg'

export default () => {
  const [dims, setDims] = useState({ x: 1200, y: 1200 })
  const [yScroll, setYScroll] = useState(0)
  const [height, setHeight] = useState()

  useEffect(() => {
    setDims({ x: window.innerWidth, y: window.innerHeight })
    setHeight(window.document.body.scrollHeight)
    window.addEventListener('resize', () =>
      setDims({ x: window?.innerWidth || 0, y: window.innerHeight }),
    )
    window.addEventListener('scroll', () => {
      setYScroll(window.pageYOffset)
      setDims({ x: window.innerWidth, y: window.innerHeight })
    })
  }, [])

  return (
    <>
      <Background width={'50%'} height={'100%'} style={{ position: 'relative', zIndex: 501 }} />
      {dims.x > 800 && (
        <div
          style={{
            position: 'absolute',
            top: dims.x / 50,
            left: dims.x / 50,
            zIndex: 502,
          }}
        >
          <Link to="/" aria-label="home">
            <div style={dims.x > 1200 ? { transform: 'scale(1.2)' } : { transform: 'scale(0.7)' }}>
              <Logo />
            </div>
          </Link>
        </div>
      )}
      <Header dims={dims} scroll={yScroll} height={height} />
      <div style={styles.container}>
        <SEO />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: dims.x > 1200 ? 'flex-start' : 'center',
            width: '100%',
            maxWidth: 1200,
            marginLeft: dims.x > 1200 ? 150 : 0,
            marginTop: dims.x > 1200 ? 100 : 0,
          }}
        >
          <h1
            style={{
              width: '80%',
              color: colors.dark_blue,
              fontSize: 37,
              textAlign: dims.x > 1200 ? 'left' : 'center',
            }}
          >
            Politique de confidentialité
          </h1>
          <div
            style={{
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
              width: '80%',
              overflowY: 'auto',
              marginBottom: 24,
            }}
          >
            <div>
              <h2 style={{ color: '#4080FE' }}>Préambule</h2>
              <p>
                La protection de vos données à caractère personnel est un élément primordial de
                notre relation, que vous soyez client ou utilisateur de nos services et sites. Cette
                relation est fondée sur un principe de transparence visant à respecter votre vie
                privée conformément à la règlementation en vigueur en France et en Europe notamment
                en application de la loi informatique et libertés et du Règlement Général sur la
                Protection des Données (RGPD), la « Réglementation ». La présente politique vous
                informe sur les catégories de données à caractère personnel que nous traitons, la
                façon dont nous les utilisons, les catégories de destinataires auxquels nous les
                communiquons, ainsi que sur les droits dont vous disposez. La présente politique
                décrit la façon dont EXPLIQUE MOI PYTHAGORE recueille et utilise vos informations
                personnelles pour vous fournir leur service. La présente politique s’applique à tous
                les utilisateurs de services, notre application, site Web, fonctionnalités. Cette
                politique s’applique aux clients-élève, Parents
              </p>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>Quelles données recueillons-nous ?</h2>
              <p>
                EXPLIQUE MOI PYTHAGORE peut recueillir directement auprès de vous ou indirectement
                via des tiers des données à caractère personnel. Conformément au principe de
                minimisation, nous ne collectons que les données nécessaires au regard des finalités
                pour lesquelles elles sont traitées. Voici les différentes catégories de données
                collectées lors de votre inscription et dans le cadre de votre utilisation de nos
                services en ligne : Données d’identification (notamment nom, prénom, date de
                naissance, sexe, classe) .
              </p>
            </div>
            <ul>
              <li>Données de contact (adresse postale, électronique, téléphone) .</li>
              <li>
                Données de connexion (notamment adresse IP de votre ordinateur, logs de connexion et
                d’usage) .
              </li>
              <li>Données financières (moyens et historiques de paiement) .</li>
              <li>Données concernant des avis sur notre service .</li>
              <li>Données commerciales (abonnement utilisé) .</li>
              <li>Données de contenus.</li>
            </ul>
            <p>D’autres informations peuvent provenir d’autres sources compnant notamment</p>
            <ul>
              <li>les utilisateurs envoyant des commentaires, comme des notes .</li>
              <li>les utilisateurs demandant des services pour vous ou en votre nom .</li>
              <li>
                les utilisateurs ou autres personnes fournissant des informations dans le cadre de
                réclamations ou de litiges .
              </li>
              <li>les sources publiques ou administratives .</li>
            </ul>
            <p>
              EXPLIQUE MOI PYTHAGORE peut associer les informations recueillies auprès de ces
              sources à d’autres informations en sa possession.
            </p>
            <p>
              La collecte de données personnelles et confidentielles des utilisateurs sont partagées
              comme suit :
            </p>
            <div>
              <p style={{ color: '#003D8D', fontWeight: 'bold' }}>Utilisateur PARENT :</p>
              <p>
                Lors de la commande de cours, le professeur aura accès aux informations que vous
                aurez renseignés suivantes : Adresse du cours sélectionné Niveau et prénom de votre
                enfant pour le cours Une fois que vous aurez sélectionné le profil du professeur
                souhaitez, ce dernier aura accès à votre numéro de téléphone pour vous contacter.
              </p>
            </div>
            <div>
              <p style={{ color: '#003D8D', fontWeight: 'bold' }}>Utilisateur PROFESSEUR :</p>
              <p>
                Lors de la proposition d’exécution du cours, le parent aura accès aux informations
                que vous aurez renseignés suivantes : Photo de profil que vous aurez renseigné Votre
                cursus scolaire, universitaire et professionnel (que vous aurez mentionné pour votre
                CV sur votre profil) Une fois le cours et votre profil validé par le parent, ce
                dernier aura accès à votre numéro de téléphone.
              </p>
            </div>
            <div>
              <p style={{ color: '#003D8D', fontWeight: 'bold' }}>Procédure de traitement :</p>
              <p>
                Lors de la proposition d’exécution du cours, le parent aura accès aux informations
                que vous aurez renseignés suivantes : Photo de profil que vous aurez renseigné Votre
                cursus scolaire, universitaire et professionnel (que vous aurez mentionné pour votre
                CV sur votre profil) Une fois le cours et votre profil validé par le parent, ce
                dernier aura accès à votre numéro de téléphone.
              </p>
            </div>
            <div>
              <p style={{ color: '#003D8D', fontWeight: 'bold' }}>Procédure de suppression :</p>
              <p>
                Les données sont sauvegardées sur un serveur sécurisé (OVH) et la sauvegarde sur le
                serveur (AWS). En cas de suppssion de compte, sur simple demande par mail (à
                condition que l’utilisateur se soit acquitté de toute somme dûe et qu’aucun cours
                n’est pas à venir), nous supprimerons de notre base de données et du serveur de
                sauvegarde toutes informations concernant votre compte à supprimer. A noter que les
                coordonnées bancaires ne sont pas enregistrées chez nous. Notre prestataire bancaire
                BNPP PARIBAS se charge de la partie prélèvement et paiement et sont en charges de la
                protection de ces données.
              </p>
            </div>
            <div>
              <p style={{ color: '#003D8D', fontWeight: 'bold' }}>
                Procédure de conservation des données du développeur :
              </p>
              <p>
                En cas d’inactivité prolongées d’un compte utilisateur 2 ans et 7 mois), le compte
                est désactivé et remis à zéro. (Les informations telles que l’adresse postale,
                numéro de téléphone, profil enfant, CV, photos de profils) en supposant que ces
                informations aient été renseignées au préalable, seront supprimés. Seule l’adresse
                mail rattaché au compte restera active.
              </p>
              <p>
                L’adresse mail n’est pas divulgués aux utilisateurs. Si l’utilisateur ne souhaitent
                plus que son adresse mail soit active et rattaché au compte, se référé au paragraphe
                : <span style={{ color: '#4080FE' }}>Procédure de suppression .</span>
              </p>
              <p>
                La conservation par le développeur sont détaillés dans les paragraphes{' '}
                <span style={{ color: '#4080FE' }}>
                  Procédure de traitement et Procédure de suppression .
                </span>
              </p>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>Finalités</h2>
              <p>
                Vos données à caractère personnel sont collectées et traitées uniquement sur la base
                des fondements juridiques prévus par la Réglementation :
              </p>
              <p style={{ color: '#4080FE', fontWeight: 'bold' }}>
                1. À des fins d’intérêts légitimes ou, le cas échéant, sur la base de votre
                consentement :
              </p>
              <ul>
                <li>
                  Fourniture de contenus localisés et de recommandations personnalisées basés sur
                  l’analyse des usages .
                </li>
                <li>
                  Développement des produits et services d’EXPLIQUE MOI PYTHAGORE ou de tiers et
                  prospection commerciale .
                </li>
                <li>Réalisation d’études statistiques, d’analyses et de mesures d’audience.</li>
                <li>Lutte contre la fraude et prévention des impayés .</li>
                <li>Enrichissement et valorisation de la base clients/prospects .</li>
              </ul>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>Durée de conservation</h2>
              <p>
                Nous ne conservons vos données que pendant la durée nécessaire à l’accomplissement
                des finalités mentionnées cidessus ou pour nous permettre de répondre à nos
                obligations légales. Les données à caractère personnel utilisées à des fins de
                prospection commerciale peuvent être conservées pour une durée maximale de trois (3)
                ans à compter de la fin de la relation commerciale.
              </p>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>Vers quels destinataires ?</h2>
              <p>
                Vos données à caractère personnel pourront être traitées par le personnel habilité
                du groupe, des partenaires, des prestataires ou des administrations avec qui
                EXPLIQUE MOI PYTHAGORE pourra être en relation. Le recours à ces partenaires,
                prestataires ou administrations est nécessaire à la bonne exécution du contrat passé
                entre vous et EXPLIQUE MOI PYTHAGORE. Si ces destinataires sont amenés à traiter vos
                données en dehors de l’Union européenne, les transferts seront réalisés dans le
                respect de la Réglementation.
              </p>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>Quelle sécurité pour vos données ?</h2>
              <p>
                Les données sont enregistrées dans notre base de données qui elle-même est sur notre
                serveur protégé par notre prestataire hébergeur. Les données de paiements sont sous
                la sécurité de la plateforme de la banque nous fournissant le paiement sécurisé en
                ligne. Nous ne conservons aucunes données de paiement, ni d’empintes.
              </p>
            </div>

            <h1 style={{ color: '#003D8D' }}>A propos de vos droits</h1>
            <div>
              <h2 style={{ color: '#4080FE' }}>Quels sont vos droits ?</h2>
              <p>
                Vous pouvez demander à tout moment l’accès aux données à caractère personnel vous
                concernant, leur rectification, leur effacement (dans la mesure où cela n’empêche
                pas la bonne exécution du contrat ou le respect des obligations légales de EXPLIQUE
                MOI PYTHAGORE) et la limitation d’un ou plusieurs traitements particuliers de
                données vous concernant, dans les conditions prévues par la Réglementation. Vous
                disposez également du droit de modifier ou de retirer, à tout moment, les
                consentements que vous nous avez accordés pour le traitement de vos données à
                caractère personnel. Vous du droit de vous opposer à un traitement de vos données à
                caractère personnel et du droit à leur portabilité, dans les conditions fixées par
                la Réglementation. Vos données à caractère personnel peuvent être conservées ou
                supprimées après votre décès conformément à la Réglementation. Vous disposez du
                droit de donner instruction à EXPLIQUE MOI PYTHAGORE de communiquer ces données à un
                tiers que vous aurez préalablement désigné.
              </p>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>Actualisation des données transmises</h2>
              <p>Actualisation des données transmises</p>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>Modalités d’exercice</h2>
              <p>
                Vous pouvez exercer vos droits à tout moment en envoyant un courrier postal à :
                EXPLIQUE MOI PYTHAGORE 36 rue de la Grande Ceinture 94100 Saint Maur des Fossés Vous
                devrez fournir vos nom, prénom, mail d’inscription ainsi qu’une copie d’une pièce
                d’identité. Si vous n’êtes pas satisfait de nos échanges, vous avez la possibilité
                de saisir la Commission Nationale de l’Informatique et des Libertés (CNIL) à
                l’adresse suivante : CNIL – 3 place de Fontenoy – TSA 80715 – 75334 Paris cedex 07
              </p>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>En savoir plus ?</h2>
              <p>
                Contactez-nous via le site, l’application téléphone mail (
                expliquemoipythagore@gmail.com ) ou courrier : EXPLIQUE MOI PYTHAGORE, 36 Rue de la
                Grande Ceinture, 94100 Saint Maur des Fossés. La présente politique de protection
                des données à caractère personnel est susceptible d’évoluer selon les besoins
                d’EXPLIQUE MOI PYTHAGORE.
              </p>
            </div>
            <div>
              <h2 style={{ color: '#4080FE' }}>En Résumé la RGPD :</h2>
              <p>
                Pour tout traitement de données personnelles effectué en relation avec ce contrat,
                les parties se conformeront au règlement (UE) 2016/679 du Parlement Européen et du
                Conseil du 27 avril 2016, relatif à la protection des personnes physiques à l’égard
                du traitement des données à caractère personnel et à la libre circulation de ces
                données « RGPD ». <br />
                Chaque partie déclare et garantit à l’autre partie qu’elle se conformera strictement
                au RGPD pour tout traitement de données personnelles effectué en rapport avec ce
                contrat. <br />
                Si le client effectue un traitement de données personnelles du prestataire ou permet
                à un tiers de le faire, il devra en informer le prestataire, se conformer au RGPD,
                le cas échéant, donner instruction au tiers d’en faire de même et garantir qu’il s’y
                conformera. <br />
                Le prestataire traite des données personnelles du client, comme détaillé dans la
                politique de traitement des données personnelles figurant dans le présent contrat.{' '}
                <br />
                Si le prestataire effectue d’autres traitements de données personnelles du client,
                ou permet à un tiers de le faire, il devra en informer le client et se conformer au
                RGPD et le cas échéant, donner instruction au tiers d’en faire de même et garantir
                qu’il s’y conformera. <br />
                Si la prestataire de services induit le traitement de données personnelles de tiers,
                ces données personnelles devront rester confidentielles.
                <br />
                L’enseignant dispose de droits sur les données personnelles le concernant, à savoir
                :
                <p style={{ fontWeight: 'bold', fontFamily: 'monospace' }}>
                  - Droit d’obtenir des informations sur les données détenues par le prestataire et
                  les traitements mis en œuvre . <br />
                  - Droit de recevoir des données sous forme électronique et de demander au
                  prestataire de les transmettre à un tiers . <br />
                  - Droit de modifier ou de corriger ses données personnelles . <br />
                  - Droit de demander la suppssion de ses données dans certaines circonstances .{' '}
                  <br />
                  - Droit de demander de restreindre ou de s’opposer au traitement des données .{' '}
                  <br />
                  - Droit de déposer une réclamation auprès de la Commission Nationale de
                  l’Informatique et des libertés.
                  <br />
                </p>
              </p>
            </div>
          </div>
        </div>

        <Footer dims={dims} />
      </div>
    </>
  )
}

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  body: {
    width: 1200,
    marginTop: 100,
  },
}
